import { createAction } from 'redux-starter-kit'
import { gamesRef } from '../firebase'

export const addGameStarted = createAction('ADD_GAME_STARTED')
export const addGameSucceeded = createAction('ADD_GAME_SUCCEEDED')
export const addGameFailed = createAction('ADD_GAME_FAILED')

export const addGame = (payload) => dispatch => {
  dispatch(addGameStarted(payload.game))
  return gamesRef.add(payload.game).then((docRef) => {
    dispatch(addGameSucceeded(docRef.id))
    payload.handleNewID(docRef.id)
  }).catch((error) => {
    dispatch(addGameFailed(error.toString()))
  })
}

export const updateGameStarted = createAction('UPDATE_GAME_STARTED')
export const updateGameSucceeded = createAction('UPDATE_GAME_SUCCEEDED')
export const updateGameFailed = createAction('UPDATE_GAME_FAILED')

export const updateGame = (payload) => dispatch => {
  let game = payload.game
  dispatch(updateGameStarted(game))
  return gamesRef.doc(payload.gameID).update(game).then(() => {
    dispatch(updateGameSucceeded())
    payload.handleSuccess()
  }).catch((error) => {
    dispatch(updateGameFailed(error.toString()))
  })
}

export const fetchGamesSucceeded = createAction('FETCH_GAMES_SUCCEEDED')
export const fetchGamesFailed = createAction('FETCH_GAMES_FAILED')

export const fetchGames = () => dispatch => {
  // Use descending so highest weight comes first
  return gamesRef.orderBy('weight', 'desc').onSnapshot((querySnapshot) => {
    let data = {}
    querySnapshot.docs.forEach(d => data[d.id] = d.data())
    dispatch(fetchGamesSucceeded(data))
  }, (error) => {
    dispatch(fetchGamesFailed(error.toString()))
  })
}

export const filterByTag = createAction('FILTER_BY_TAG')
export const filterBySearch = createAction('FILTER_BY_SEARCH')
