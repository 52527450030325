import { createReducer } from 'redux-starter-kit'

const gamesReducer = createReducer({}, {
  'ADD_GAME_FAILED': (state, action) => {
    alert('Something went awry! Eeek!')
    console.log("Error while adding game:")
    console.log(action.payload)
  },
  'UPDATE_GAME_FAILED': (state, action) => {
    alert('Something went awry! Eeek!')
    console.log("Error while updating game:")
    console.log(action.payload)
  },
  'FETCH_GAMES_SUCCEEDED': (state, action) => {
    return action.payload
  }
})

export default gamesReducer
