import { createReducer } from 'redux-starter-kit'

// TODO: Consider moving this elsewhere?
export const calculateTags = (games) => {

  // We could map tag strings to game ids, but tags for which that matters
  // (ones the user clicks) are going to be sparse, probably doesn't warrant
  // denormalizing the data, vs. just scanning through games to find matches
  // Instead, just tally tag counts and track "best" name
  // so we can make an ordered list below
  let tagsToInfos = Object.values(games).reduce((tagsToInfos, game) => {
    let gameTagsToInfos = game.tags.reduce((acc, tag) => {
      let tagToInfo = {}
      let canonicalTag = tag.toLowerCase()
      // Check that t is non-empty and not a dup
      if (tag && !acc[canonicalTag]) {
        // Since games are ordered by weight, we can use weight a a heuristic
        // to determine which tag makes the best label for the group
        // because higher weight => better content => better tag label
        // TODO: Explicitly choose "best" tag based on capitalization.
        tagToInfo[canonicalTag] = {
          name: (tagsToInfos[canonicalTag] || {}).name || tag,
          count: ((tagsToInfos[canonicalTag] || {}).count || 0) + 1
        }
      }
      return Object.assign(acc, tagToInfo)
    }, {})

    return Object.assign(tagsToInfos, gameTagsToInfos)
  }, {})

  // Get values and sort by count
  let tags = Object.values(tagsToInfos).sort((a, b) => {
    let diff = b.count - a.count
    if (diff === 0) {
      diff = a.name.localeCompare(b.name)
    }
    return diff
  })

  return tags
}

const tagsReducer = createReducer([], {
  'FETCH_GAMES_SUCCEEDED': (state, action) => {
    return calculateTags(action.payload)
  }
})

export default tagsReducer
