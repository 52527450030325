import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { filterBySearch } from '../actions'
import store from '../store'
import './Search.css'

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: props.searchFilter || ""
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault()
    this.props.searchText(this.state.searchText)
  }

  render() {
    return (
      <form className="Search" onSubmit={this.handleSubmit}>
        <input type="text" name="searchText" className="Search-text" placeholder="Search"
          value={this.state.searchText} onChange={this.handleInputChange} />
        <button type="submit">
          <span role="img" aria-label="search">🔍</span>
        </button>
      </form>
    )
  }
}

Search.propTypes = {
  searchFilter: PropTypes.string,
  searchText: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  searchFilter: state.filter.searchFilter
})

const mapDispatchToProps = dispatch => ({
  // So passing state via payload LOOKS super bad, but it's one of the options that
  // Redux gives here: https://redux.js.org/recipes/structuring-reducers/beyond-combinereducers#sharing-data-between-slice-reducers
  // Custom combineReducers doesn't seem to play nicely with createReducer.
  // Reducer of reducers might work, but adding to payload seems lower-impact
  searchText: (searchText) => dispatch(filterBySearch({
    searchText: searchText,
    games: store.getState().games
  }))
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)
