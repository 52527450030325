import { connect } from 'react-redux'
import { filterByTag } from '../actions';
import TagList from '../components/TagList'
import { calculateTags } from '../reducers/tags'

const getVisibleTags = (state) => {
  let { tags, games } = state
  if (state.filter.searchFilter) {
    let matchingGames = {}
    state.filter.matchingGameIDs.forEach((id) => matchingGames[id] = games[id])
    tags = calculateTags(matchingGames)
  }

  return tags
}

const getVisibleGameCount = (state) => {
  if (state.filter.searchFilter) {
    return state.filter.matchingGameIDs.length
  }

  return Object.entries(state.games).length
}

const mapStateToProps = state => ({
  tags: getVisibleTags(state),
  taggedItemTotalCount: getVisibleGameCount(state),
  selectedTagName: state.filter.tagFilter
})

const mapDispatchToProps = dispatch => ({
  selectTag: (tagName) => dispatch(filterByTag(tagName))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagList)
