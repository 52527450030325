import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import history from '../history'
import GameForm from '../containers/GameForm'
import { addGame } from '../actions'

const initialState = {
  name: '',
  emoji: '',
  weight: 0,
  duration_minutes: '',
  random_card_order: false,
  tags: [],
  question_md: ''
}

class GameCreate extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(game) {
    // On success, navigate to viewing new ID
    // Note: Passing a callback via payload isn't ideal, but seems a bit better
    // than passing history, which is recommended by react-router here, item 2:
    // https://reacttraining.com/react-router/web/guides/redux-integration
    // and it's simpler than adding a package like connected-react-router.
    let handleNewID = (newID) => history.push('/games/'+newID)
    this.props.dispatch(addGame({game: game, handleNewID: handleNewID}))
    this.setState(initialState)
  }

  render() {
    return (
      <GameForm
        initialState={initialState}
        handleValidSubmit={this.handleSubmit}
        submitText="Add Game" />
    )
  }
}

GameCreate.propTypes = {
  dispatch: PropTypes.func.isRequired
}

export default connect()(GameCreate)
