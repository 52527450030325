import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './GameItem.css'

const GameItem = ({ id, name, question_md, weight, tags, emoji }) => {
  let questionPreview = question_md
  if (question_md && question_md.length > 200) {
    questionPreview = question_md.substring(0,200)
  }

  return (
    <li>
      <div className="GameItem-display-info">
        <span>
          <span role="img" aria-label="weight">⚖️</span>
          {weight}
        </span>
        <span className="GameItem-tags">
          <span role="img" aria-label="tag">🏷</span>
          {tags.join(', ')}
        </span>
      </div>
      <Link className="GameItem-edit-link primary" to={`/games/${id}/edit`}>
        Edit
      </Link>
      <Link to={`/games/${id}`}>
        <div className="GameItem-name">{emoji} {name}</div>
        <div className="GameItem-question-preview">{questionPreview}</div>
      </Link>
    </li>
  )
}

GameItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  question_md: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  emoji: PropTypes.string
}

export default GameItem
