import React from 'react'
import './GameEmpty.css'

const GameEmpty = () => (
  <div className="GameEmpty">
    <h3>
      Welcome to the Game Library Admin tool!
    </h3>
    <p>
      It should be pretty self-explanatory! If you have questions or feedback,
      contact (email address)
    </p>
  </div>
)

export default GameEmpty
