import { combineReducers } from 'redux'
import gamesReducer from './games'
import tagsReducer from './tags'
import filterReducer from './filter'
import statusReducer from './status'

const rootReducer = combineReducers({
  games: gamesReducer,
  tags: tagsReducer,
  filter: filterReducer,
  status: statusReducer
})

export default rootReducer
