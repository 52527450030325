import { createReducer } from 'redux-starter-kit'

const getMatchingGameIDs = (games, searchText) => {
  let matchingIDs = []

  Object.entries(games).forEach((entry) => {
    let [gameID, game] = entry
    let { name, question_md, tags, emoji } = game
    if (
      name.toLowerCase().indexOf(searchText) >= 0
      || (emoji || '').indexOf(searchText) >= 0
      || tags.map((t) => t.toLowerCase().indexOf(searchText) >= 0).reduce((a, b) => a || b)
      || question_md.toLowerCase().indexOf(searchText) >= 0
    ) {
      matchingIDs.push(gameID)
    }
  })

  return matchingIDs
}

const filterReducer = createReducer({ tagFilter: "", searchFilter: "" }, {
  'FILTER_BY_TAG': (state, action) => {
    state.tagFilter = action.payload.toLowerCase()
  },
  'FILTER_BY_SEARCH': (state, action) => {
    state.searchFilter = action.payload.searchText.toLowerCase()
    state.matchingGameIDs = getMatchingGameIDs(action.payload.games, state.searchFilter)
  }
})

export default filterReducer
