import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import Autosuggest from 'react-autosuggest'
import './GameForm.css'

class GameForm extends Component {

  constructor(props) {
    super(props)

    let { initialState, initialGame } = props
    this.state = initialState || this.getStateFromGame(initialGame)
    this.state.errorMessage = null

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleTagsChange = this.handleTagsChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    var value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    });
  }

  handleTagsChange(tags) {
    this.setState({tags})
  }

  // convert from game structure to form structure, to populate form
  getStateFromGame(game) {
    let state = {}
    state.name = game.name
    state.emoji = game.emoji
    state.tags = game.tags
    state.weight = "" + game.weight
    state.duration_minutes = "" + Math.round(game.duration_seconds / 60)
    state.question_md = game.question_md
    state.random_card_order = game.random_card_order

    return state
  }

  // convert from form structure to game structure
  getGameFromState(state) {
    let game = {game_type: 'answer_question'}
    game.name = state.name.trim()
    game.emoji = state.emoji.trim()
    // Filter to get only unique tags
    game.tags = state.tags.filter((v, i, self) => self.indexOf(v) === i)
    // Call parse because type=number value is sometimes a number, sometimes string. Fun!
    game.weight = state.weight && state.weight !== 0 ? parseFloat(state.weight) : state.weight
    // Note: if duration_minutes is invalid, duration_seconds will be 0, hence invalid
    game.duration_seconds = (parseInt(state.duration_minutes) || 0) * 60
    game.question_md = state.question_md.trim()
    game.random_card_order = state.random_card_order

    return game
  }

  handleSubmit(event) {
    event.preventDefault()

    let game = this.getGameFromState(this.state)

    let errorMessage;
    if (!game.name || !game.question_md || !game.tags) {
      errorMessage = "All text fields except emoji are required."
    } else if (!Number.isInteger(game.duration_seconds) || game.duration_seconds <= 0) {
      errorMessage = "Duration should be a positive number."
    } else if (!Number.isFinite(game.weight)) {
      errorMessage = "Weight should be a number."
    }

    if (!errorMessage) {
      this.props.handleValidSubmit(game)
    } else {
      this.setState({errorMessage: errorMessage})
    }
  }

  handleCancel(event) {
    event.preventDefault()
    this.props.handleCancel()
  }

  render() {
    let { editIsPending, submitText, tags } = this.props

    // Autocomplete for tags
    function autocompleteRenderInput ({addTag, ...props}) {
      const handleOnChange = (e, {newValue, method}) => {
        if (method === 'enter') {
          e.preventDefault()
        } else {
          props.onChange(e)
        }
      }

      const inputValue = (props.value && props.value.trim().toLowerCase()) || ''
      const inputLength = inputValue.length

      let suggestions = tags.filter((tag) => {
        return tag.name.toLowerCase().slice(0, inputLength) === inputValue
      })

      return (
        <Autosuggest
          ref={props.ref}
          suggestions={suggestions}
          shouldRenderSuggestions={(value) => value && value.trim().length > 0}
          getSuggestionValue={(suggestion) => suggestion.name}
          renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
          inputProps={{...props, onChange: handleOnChange}}
          onSuggestionSelected={(e, {suggestion}) => {
            addTag(suggestion.name)
          }}
          onSuggestionsClearRequested={() => {}}
          onSuggestionsFetchRequested={() => {}}
        />
      )
    }

    let cancelButton = ""
    if (this.props.handleCancel) {
      cancelButton = (<button onClick={this.handleCancel}>Cancel</button>)
    }

    return (<div>
        <form className="createGame" onSubmit={this.handleSubmit}>
          <fieldset disabled={editIsPending ? "disabled" : ""}>
            <p className="error-message">{this.state.errorMessage}</p>
            <input name="name" type="text" value={this.state.name} placeholder="Title" onChange={this.handleInputChange} />
            <label>
              Emoji
              <input name="emoji" type="text" value={this.state.emoji} size="2" maxLength="4" onChange={this.handleInputChange} />
            </label>
            <label>
              Duration in minutes
              <input name="duration_minutes" type="number" value={this.state.duration_minutes} min="1" max="1000" onChange={this.handleInputChange} />
            </label>
            <label>
              Show cards in a random order
              <input name="random_card_order" type="checkbox" value={this.state.random_card_order} onChange={this.handleInputChange} />
            </label>
            <label>
              Featured &ldquo;Weight&rdquo;
              <input name="weight" type="number" value={this.state.weight} onChange={this.handleInputChange} />
            </label>
            <TagsInput
              value={this.state.tags} onChange={this.handleTagsChange}
              renderInput={autocompleteRenderInput}
            />
            <textarea name="question_md" value={this.state.question_md} rows="16" placeholder="Questions" onChange={this.handleInputChange} />
            <span className="detail-create-question-note">*Use 2 line breaks to separate questions.</span>
            <button type="submit">
              {submitText || "Save"}
            </button>
            {cancelButton}
          </fieldset>
        </form>
      </div>)
  }
}

const mapStateToProps = state => ({
  editIsPending: state.status.editIsPending,
  tags: state.tags
})

// TODO: Also check for fields of initialGame and initialState
const requiredInitialStateCheck = (props, propName, componentName) => {
  if (!props.initialState && !props.initialGame) {
    return new Error(`One of 'initialState' or 'initialGame' is required by '${componentName}' component.`)
  }
}

GameForm.propTypes = {
  initialGame: requiredInitialStateCheck,
  initialState: requiredInitialStateCheck,
  submitText: PropTypes.string,
  handleValidSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func
}

export default connect(mapStateToProps)(GameForm)
