import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { filterByTag } from '../actions'
import ClickableLink from '../components/ClickableLink'
import './GameView.css'

const GameView = (props) => {
  let { match, games, selectTag } = props
  const gameID = match.params.id
  let game = games[gameID]
  if (!game) {
    return (<div></div>);
  }

  let duration_minutes = Math.round(game.duration_seconds / 60);
  let questions = game.question_md.split(/\n+/g)

  return (
    <div className="GameViewContainer">
      <Link className="GameViewEdit primary" to={"/games/"+gameID+"/edit"}>Edit</Link>
      <h3>{game.emoji} {game.name}</h3>
      <p>Tags: <span>
        {game.tags.map((t) =>
          (<ClickableLink key={t} className="primary"
              text={t}
              handleClick={() => selectTag(t)}
          />)
        )
        .reduce((prev, curr) => [prev, ', ', curr])}
      </span></p>
      <p>Featured with weight: <span>{game.weight}</span></p>
      <p>Duration: <span>{duration_minutes} min</span></p>
      <p>Cards shown: <span>in {game.random_card_order ? 'random' : ''} order</span></p>
      <ul className={game.random_card_order ? 'questions-unordered' : 'questions-ordered'}>
        {questions.map((q, i) =>
          <li key={"question-"+i}>{q}</li>
        )}
      </ul>
    </div>
  )
}

GameView.propTypes = {
  games: PropTypes.object.isRequired,
  selectTag: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  games: state.games
})

const mapDispatchToProps = dispatch => ({
  selectTag: (tagName) => dispatch(filterByTag(tagName))
})

export  default connect(mapStateToProps, mapDispatchToProps)(GameView)
