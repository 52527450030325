import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import history from '../history'
import { updateGame } from '../actions'
import GameForm from '../containers/GameForm'

class GameEdit extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  handleSubmit(game) {
    let gameID = this.props.match.params.id
    // On success, navigate to viewing new ID
    // Note: Passing a callback via payload isn't ideal, but seems a bit better
    // than passing history, which is recommended by react-router here, item 2:
    // https://reacttraining.com/react-router/web/guides/redux-integration
    // and it's simpler than adding a package like connected-react-router.
    let handleSuccess = () => history.push('/games/' + gameID)
    this.props.dispatch(updateGame({
      gameID: gameID,
      game: game,
      handleSuccess: handleSuccess
    }))
  }

  handleCancel() {
    let gameID = this.props.match.params.id
    history.push('/games/' + gameID)
  }

  render() {
    let { games, match } = this.props
    let gameID = match.params.id

    let currentGame = games[gameID]
    if (currentGame) {
      return (
        <GameForm
          key={gameID}
          initialGame={currentGame}
          handleValidSubmit={this.handleSubmit}
          handleCancel={this.handleCancel}
          />
      )
    }

    return (<div></div>)
  }
}

GameEdit.propTypes = {
  dispatch: PropTypes.func.isRequired,
  games: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  games: state.games
})

export default connect(mapStateToProps)(GameEdit)
