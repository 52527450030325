import { connect } from 'react-redux'
import { fetchGames } from '../actions';
import GameList from '../components/GameList'

const getVisibleGames = (state) => {
  let { games, filter}  = state
  let { tagFilter, searchFilter, matchingGameIDs } = filter

  let visibleGames = {}
  if (!tagFilter) {
    visibleGames = games
  } else {
    let lowerCaseTag = tagFilter.toLowerCase()
    Object.entries(games).forEach((entry) => {
      let [id, game] = entry
      if (game.tags.find((e) => e.toLowerCase() === lowerCaseTag)) {
        visibleGames[id] = game
      }
    })
  }

  if (searchFilter) {
    let matchingAndVisibleGames = {}
    matchingGameIDs.forEach((id) => {
      let game = visibleGames[id]
      if (game) {
        matchingAndVisibleGames[id] = game
      }
    })
    visibleGames = matchingAndVisibleGames
  }

  return visibleGames
}

const mapStateToProps = state => ({
  games: getVisibleGames(state)
})

const mapDispatchToProps = dispatch => ({
  fetchGames: () => dispatch(fetchGames())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameList)
