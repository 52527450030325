import { createReducer } from 'redux-starter-kit'

const statusReducer = createReducer({editIsPending: false}, {
  'ADD_GAME_STARTED': (state, action) => {
    state.editIsPending = true
  },
  'ADD_GAME_SUCCEEDED': (state, action) => {
    state.editIsPending = false
  },
  'ADD_GAME_FAILED': (state, action) => {
    state.editIsPending = false
  },
  'UPDATE_GAME_STARTED': (state, action) => {
    state.editIsPending = true
  },
  'UPDATE_GAME_SUCCEEDED': (state, action) => {
    state.editIsPending = false
  },
  'UPDATE_GAME_FAILED': (state, action) => {
    state.editIsPending = false
  },
  'FETCH_GAMES_SUCCEEDED': (state, action) => {
    state.gamesInaccessible = false
  },
  'FETCH_GAMES_FAILED': (state, action) => {
    state.gamesInaccessible = true
  }
})

export default statusReducer
