import firebase from 'firebase/app'
import 'firebase/firestore'

// Choose firebase config based on environment
// Note that using NODE_ENV won't work because it can't be overridden manually.
// See https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
var firebaseConfig = process.env.REACT_APP_ENV === 'production'
  ? {
    apiKey: "AIzaSyDmr-VO4UT6EOjKYiZBnpOZFIEe57d1QaI",
    authDomain: "icebreaker.video",
    projectId: "icebreaker-prod",
    storageBucket: "icebreaker-prod.appspot.com",
    messagingSenderId: "825955201493"
   }
  : {
    apiKey: "AIzaSyAA_4El2PmzOXiQo8uzcmJHqCiXhskY0vY",
    authDomain: "icebreaker-staging.firebaseapp.com",
    projectId: "icebreaker-staging",
    storageBucket: "icebreaker-staging.appspot.com",
    messagingSenderId: "470626877536"
  }

export const firebaseApp = firebase.initializeApp(firebaseConfig)

export const gamesRef = firebase.firestore().collection("games")
