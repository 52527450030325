import React, { Component } from 'react'
import { Router, Route, Redirect, Switch, Link } from 'react-router-dom'
import history from '../history'
import './App.css';
import withFirebaseAuth from 'react-with-firebase-auth'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import { firebaseApp } from '../firebase'

// TODO: consider moving these into a component, just handle auth here
import GameEmpty from './GameEmpty'
import GameCreate from '../containers/GameCreate'
import GameView from '../containers/GameView'
import GameEdit from '../containers/GameEdit'
import AddGame from './AddGame'
import Search from '../containers/Search'
import VisibleTagList from '../containers/VisibleTagList'
import VisibleGameList from '../containers/VisibleGameList'

const firebaseAppAuth = firebaseApp.auth()

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
}

class App extends Component {
  render() {
    const { user, signOut, signInWithGoogle, gamesInaccessible } = this.props
    const isProduction = process.env.REACT_APP_ENV === 'production'

    // Return a signin button if no user
    if (!user) {
      return (<button className="App-loginButton" onClick={signInWithGoogle}>Sign in with Google</button>)
    }

    // Return a message if there was an error getting games.
    if (gamesInaccessible) {
      return (<p className="App-message">
          Unable to load games. Are you an admin? If so, try again later maybe?
        </p>)
    }

    return (
      <Router history={history}>
        <div className="App">
          <header className={`App-header App-header--${isProduction ? "production" : "staging"}`}>
              <Link to="/">Game Library Admin</Link>
              <button className="App-logoutButton" onClick={signOut}>Sign out {user.displayName}</button>
          </header>
          <div className="App-toolbar">
            <AddGame />
            <Search />
          </div>
          <div className="left-column">
            <VisibleTagList />
          </div>
          <div className="center-column">
            <VisibleGameList />
          </div>
          <div className="right-column">
            <Route exact path="/" component={() => (<Redirect to="/games" />)} />
            <Route exact path="/games" component={GameEmpty} />
            <Switch>
              <Route path="/games/create" component={GameCreate} />
              <Route exact path="/games/:id" component={GameView} />
              <Route path="/games/:id/edit" component={GameEdit} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

// TODO: Consider not using HOC. It's cute but easy enough to replace
export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App)
