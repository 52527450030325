import React from 'react'
import PropTypes from 'prop-types'
import ClickableLink from './ClickableLink'
import './TagItem.css'

const TagItem = ({ name, count, isSelected, selectTag }) => (
  <li className={isSelected ? "TagItem-selected" : "TagItem-unselected"}>
    <ClickableLink text={name + ` (${count})`} handleClick={selectTag} />
  </li>
)

TagItem.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectTag: PropTypes.func.isRequired
}

export default TagItem
