import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GameItem from './GameItem'

class GameList extends Component {

  componentDidMount() {
    this.props.fetchGames()
  }

  render() {
    let { games } = this.props
    return (
      <ul className="GameList">
        {Object.entries(games).map(item => {
          let [id, game] = item
          return (<GameItem
              key={id}
              id={id}
              {...game}
            />
          )
        })
        }
      </ul>
    );
  }
}

GameList.propTypes = {
  games: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  fetchGames: PropTypes.func.isRequired
}

export default GameList
