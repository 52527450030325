import React from 'react'
import PropTypes from 'prop-types'
import TagItem from './TagItem'

const ALL_TAGS_NAME = "ALL_TAGS"
const ALL_TAGS_DISPLAY_NAME = 'All'

const isReservedTagName = (tagName) => {
  return tagName === ALL_TAGS_NAME || tagName === ALL_TAGS_DISPLAY_NAME
}

const TagList = ({ tags, taggedItemTotalCount, selectedTagName, selectTag }) => {
  return (
    <ul>
      <TagItem
        key={ALL_TAGS_NAME}
        name={ALL_TAGS_DISPLAY_NAME}
        count={taggedItemTotalCount}
        isSelected={!selectedTagName}
        selectTag={() => selectTag("")}
      />

      {tags.map(tag => {
        let { name } = tag
        return (isReservedTagName(name) ? '' :
          <TagItem
            key={name}
            isSelected={selectedTagName === name.toLowerCase()}
            {...tag}
            selectTag={() => selectTag(name)}
          />
        )
      })}
    </ul>
  )
}

TagList.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({name: PropTypes.string.isRequired})
  ).isRequired,
  taggedItemTotalCount: PropTypes.number.isRequired,
  selectedTagName: PropTypes.string.isRequired,
  selectTag: PropTypes.func.isRequired
}

export default TagList
