import { connect } from 'react-redux'
import App from '../components/App'

const mapStateToProps = state => ({
  gamesInaccessible: state.status.gamesInaccessible
})

export default connect(
  mapStateToProps
)(App)
